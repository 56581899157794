// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-education-jsx": () => import("/opt/build/repo/src/pages/education.jsx" /* webpackChunkName: "component---src-pages-education-jsx" */),
  "component---src-pages-experience-jsx": () => import("/opt/build/repo/src/pages/experience.jsx" /* webpackChunkName: "component---src-pages-experience-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("/opt/build/repo/src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-toolbox-jsx": () => import("/opt/build/repo/src/pages/toolbox.jsx" /* webpackChunkName: "component---src-pages-toolbox-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

